<template>
  <div>
    <!-- 未学习 -1  学习中 0 已学完 1 ？？这样 -->
    <!-- <p class="title">理论课程</p> -->
    <div class="lesson-item" v-for="(item, index) of list1" :key="item.id" :class="{lessonShow: item.show}">
      <div
        class="title1"
        v-if="item.lesson && item.lesson.length"
        @click="changeShow(index, 1)"
      >
        <p>{{ item.name }}</p>
        <button @click.stop="toViewImg(item)" v-if="item.maps && item.maps.length">
          思维导图
        </button>
        <i
          :class="{
            'el-icon-arrow-down': !item.show,
            'el-icon-arrow-up': item.show,
          }"
        ></i>
      </div>
      <div class="title1" v-else @click="toPlay(1, item)">
        <img :src="lastStudy"  v-if="item.record_status == 1"/>

        <p>
          <!-- 学习状态 record_status == 1为上次学习到 -->
          {{ item.name }}
          <!-- <span class="green" v-if="item.is_try == 1">试听</span> -->
          <span class="blue" v-if="item.study_status == 0">学习中</span>
          <span class="red" v-if="item.study_status == -1">未学习</span>
          <span class="green" v-if="item.study_status == 1">已学完</span>
          <span class="green" v-if="item.test_status == 1">随堂练习已通过</span>
          <span class="red" v-if="item.test_status == 2">随堂练习未通过</span>
        </p>
        <button @click.stop="toViewImg(item)" v-if="item.maps && item.maps.length">
          思维导图
        </button>
        <img  :src="currentId == item.id ? leftPlay : playImg"/>
      </div>
      <div
        class="lesson-detail"
        v-if="item.show && item.lesson && item.lesson.length"
      >
        <div v-for="(v, i) in item.lesson" :key="i"  :class="{lessonShow: v.show}">
          <div class="title2" v-if="v.speak && v.speak.length">
            <p>{{ v.name }}</p>
            <button @click.stop="toViewImg(v)" v-if="v.maps && v.maps.length">
              思维导图
            </button>
          </div>
          <div class="title2" v-else @click="toPlay(2,v)">
            <img :src="lastStudy"  v-if="v.record_status == 1"/>

            <p>
              {{ v.name }}
              <!-- <span class="green" v-if="v.is_try == 1">试听</span> -->
              <span class="blue" v-if="v.study_status == 0">学习中</span>
              <span class="red" v-if="v.study_status == -1">未学习</span>
              <span class="green" v-if="v.study_status == 1">已学完</span>
              <span class="green" v-if="v.test_status == 1">随堂练习已通过</span>
              <span class="red" v-if="v.test_status == 2">随堂练习未通过</span>
            </p>
            <img  :src="currentId == v.id ? leftPlay : playImg" />
          </div>
          <div class="list3" v-if="v.speak && v.speak.length">
            <div
              class="title2"
              :class="{lessonShow: vv.show}"
              v-for="(vv, ii) in v.speak"
              :key="ii"
              @click="toPlay(3, vv)"
            >
            <img :src="lastStudy"  v-if="vv.record_status == 1"/>

              <p>
                {{ vv.name }}
                <!-- <span class="green" v-if="vv.is_try == 1">试听</span> -->
                <span class="blue" v-if="vv.study_status == 0">学习中</span>
                <span class="red" v-if="vv.study_status == -1">未学习</span>
                <span class="green" v-if="vv.study_status == 1">已学完</span>
                <span class="green" v-if="vv.test_status == 1">随堂练习已通过</span>
                <span class="red" v-if="vv.test_status == 2">随堂练习未通过</span>
              </p>
              <img  :src="currentId == vv.id ? leftPlay : playImg" />
            </div>
          </div>
          <!-- <detail-examin :list="v.speak" :testInfo="v.test" v-if="v.test.id"></detail-examin> -->
        </div>
      </div>
      <!-- <detail-examin :list="item.lesson" :testInfo="item.test" v-if="item.test.id && (!( item.lesson && item.lesson.length) || item.show)" style="margin-bottom: 10px"></detail-examin> -->
    </div>
    <p class="title" v-if="list2.length">实操课程</p>
    <div class="lesson-item" v-for="(item, index) of list2" :key="item.id">
      <div
        class="title1"
        v-if="item.lesson && item.lesson.length"
        @click="changeShow(index, 2)"
      >
        <p>{{ item.name }}</p>
        <button @click.stop="toViewImg(item)" v-if="item.maps && item.maps.length">
          思维导图
        </button>
        <i
          :class="{
            'el-icon-arrow-down': !item.show,
            'el-icon-arrow-up': item.show,
          }"
        ></i>
      </div>
      <div class="title1" v-else @click="toPlay2(1, item)">
        <img :src="lastStudy"  v-if="item.record_status == 1"/>

        <p>
          {{ item.name }}
          <!-- <span class="green" v-if="item.is_try == 1">试听</span> -->
          <span class="blue" v-if="item.study_status == 0">学习中</span>
          <span class="red" v-if="item.study_status == -1">未学习</span>
          <span class="green" v-if="item.study_status == 1">已学完</span>
          <span class="green" v-if="item.test_status == 1">随堂练习已通过</span>
          <span class="red" v-if="item.test_status == 2">随堂练习未通过</span>
        </p>
        <button @click.stop="toViewImg(item)" v-if="item.maps && item.maps.length">
          思维导图
        </button>
        <img  :src="currentId == item.id ? leftPlay : playImg" />
      </div>
      <div
        class="lesson-detail"
        v-if="item.show && item.lesson && item.lesson.length"
      >
        <div v-for="(v, i) in item.lesson" :key="i">
          <div class="title2" v-if="v.speak && v.speak.length">
            <p>{{ v.name }}</p>
            <button @click.stop="toViewImg(v)" v-if="v.maps.length">
              思维导图
            </button>
          </div>
          <div class="title2" v-else @click="toPlay2(2, item, v)">
            <img :src="lastStudy"  v-if="v.record_status == 1"/>

            <p>
              {{ v.name }}
              <!-- <span class="green" v-if="v.is_try == 1">试听</span> -->
              <span class="blue" v-if="v.study_status == 0">学习中</span>
              <span class="red" v-if="v.study_status == -1">未学习</span>
              <span class="green" v-if="v.study_status == 1">已学完</span>
              <span class="green" v-if="v.test_status == 1">随堂练习已通过</span>
              <span class="red" v-if="v.test_status == 2">随堂练习未通过</span>
            </p>
            <img  :src="currentId == v.id ? leftPlay : playImg" />
          </div>
          <div class="list3" v-if="v.speak && v.speak.length">
            <div
              class="title2"
              v-for="(vv, ii) in v.speak"
              :key="ii"
              @click="toPlay2(3, item, v, vv)"
            >
            <img :src="lastStudy"  v-if="vv.record_status == 1"/>

              <p>
                {{ vv.name }}
                <!-- <span class="green" v-if="vv.is_try == 1">试听</span> -->
                <span class="blue" v-if="vv.study_status == 0">学习中</span>
                <span class="red" v-if="vv.study_status == -1">未学习</span>
                <span class="green" v-if="vv.study_status == 1">已学完</span>
                <span class="green" v-if="vv.test_status == 1">随堂练习已通过</span>
                <span class="red" v-if="vv.test_status == 2">随堂练习未通过</span>
              </p>
              <img :src="currentId == vv.id ? leftPlay : playImg" />
            </div>
          </div>
        </div>
      </div>
      <detail-video :list="item.lesson" :testInfo="{
        time:item.video_time,
        name:item.name,
        status_name:item.video_status,
        video_url:item.video_url,
        id:item.id,
        discipline_id:item.discipline_id,
        study_status: item.study_status
      }" v-if="!( item.lesson && item.lesson.length) || item.show" style="margin-bottom: 10px"></detail-video>
    </div>
    <el-dialog title="思维导图" :visible.sync="dialogTableVisible">
      <div style="height: 480px; overflow: auto">
        <img
          :src="item"
          v-for="(item, index) of imgList"
          :key="index"
          alt=""
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import detailExamin from './detailExamin.vue'
import detailVideo from './detailVideo.vue'
import leftPlay from '@/assets/images/leftPlay.png'

import lastStudy from '@/assets/images/lastStudy.png'

export default {
  components:{detailExamin, detailVideo},

  props: ["list",'down_list', "name", "isBuy","currentId"],
  data() {
    return {
      lastStudy,
      dialogTableVisible: false,
      imgList: [],
      height: 0,
      top: 0,
      list1: [],
      list2: [],
      leftPlay,
      playImg:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/play.jpg",
    };
  },
  watch: {
    list: {
      handler(){
        let myList = this.list.map((item) => {
          item.show = false;
          return item;
        });
        this.list1 = myList
      },
      immediate: true
    },
    down_list(){
      let myList = this.down_list.map((item) => {
        item.show = false;
        return item;
      });
      this.list2 = myList
    }
  },
  computed: {
    myUser(){
      return this.$store.state.user;
    },
    vipInfo() {
      return this.$store.state.vipInfo;
    },
    platId() {
      return this.$store.state.plat.id;
    },
  },
  mounted() {},
  methods: {
    changeShow(index, type) {
      this["list" + type][index].show = !this["list" + type][index].show;
      this.$set(this["list" + type], index, this["list" + type][index]);
    },
    toPlay(type, item) {
      if(!localStorage.getItem('user')){
        this.$store.commit('changeLoginShow',true)
        return
      }
      if(this.$route.name == 'detail2'){
        this.$emit('toPlay',item)
      }else if(this.platId == '373'){
        let url = `/${this.$route.params.plat_type}/detail2?id=${this.$route.query.id}`
        if (type == 1) {
          url = url + "&chapter_id=" + item.id;
        } else if (type == 2) {
          url = url + "&chapter_id=" + item.chapter_id + "&lesson_id=" + item.id;
        } else if (type == 3) {
          url =
            url +
            "&chapter_id=" +
            item.chapter_id +
            "&lesson_id=" +
            item.lesson_id +
            "&speak_id=" +
            item.id;
        }
        this.$router.push(url);
      }else{
        let url = "./player2";
        if (type == 1) {
          url = url + "?chapter_id=" + item.id;
        } else if (type == 2) {
          url = url + "?chapter_id=" + item.chapter_id + "&lesson_id=" + item.id;
        } else if (type == 3) {
          url =
            url +
            "?chapter_id=" +
            item.chapter_id +
            "&lesson_id=" +
            item.lesson_id +
            "&speak_id=" +
            item.id;
        }
        url = url + "&id=" + this.$route.query.id;
        this.$router.push(url);
      }
      // if(this.platId == '373'){
      //   this.$emit('toPlay',item)
      // }else{
      //   let url = "./player2";
      //   if (type == 1) {
      //     url = url + "?chapter_id=" + item.id;
      //   } else if (type == 2) {
      //     url = url + "?chapter_id=" + item.chapter_id + "&lesson_id=" + item.id;
      //   } else if (type == 3) {
      //     url =
      //       url +
      //       "?chapter_id=" +
      //       item.chapter_id +
      //       "&lesson_id=" +
      //       item.lesson_id +
      //       "&speak_id=" +
      //       item.id;
      //   }
      //   url = url + "&id=" + this.$route.query.id;
      //   this.$router.push(url);
      // }
    },
    toPlay2(type, item, v, vv) {
      if(!localStorage.getItem('user')){
        this.$store.commit('changeLoginShow',true)
        return
      }
      window.sessionStorage.setItem(this.$route.query.id, true)
      if (this.isBuy == 0 && 
        ((type == 1 && item.is_try==0) || type == 2 &&  v.is_try==0 || type == 3 &&  vv.is_try==0)) {
        console.log(this.platId)
        this.$toast(this.platId=='205' ? "如果需要观看课程，请先兑换" : "请购买后开始学习");
        return;
      }
      let url = "./player2?lessonType=down";
      if (type == 1) {
        url = url + "&chapter_id=" + item.id;
      } else if (type == 2) {
        url = url + "&chapter_id=" + item.id + "&lesson_id=" + v.id;
      } else if (type == 3) {
        url =
          url +
          "&chapter_id=" +
          item.id +
          "&lesson_id=" +
          v.id +
          "&speak_id=" +
          vv.id;
      }
      url = url + "&id=" + this.$route.query.id;
      this.$router.push(url);
    },
    toViewImg(v) {
      if (v.maps.length) {
        this.imgList = v.maps.split(',');
        this.dialogTableVisible = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">

.title {
  font-size: 16px;
  padding-top: 30px;
  padding-bottom: 8px;
}
.blue,
.red,
.green {
  display: inline-block;
  margin-left: 10px;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  border-radius: 3px;
  font-size: 12px;
}
.blue {
  background: #cee7f8;
  color: #0080d6;
}
.red {
  background: #f9dbd6;
  color: #d86855;
}
.green {
  color: #0e9c58;
  background: #caefde;
}
.lesson-item {
  background: #f9f9f9;
  cursor: pointer;
  margin-bottom: 10px;
  overflow: hidden;
  .title1 {
    display: flex;
    align-items: center;
    height: 53px;
    padding-left: 10px;
    padding-right: 20px;
    >img{
      margin-right: 5px;
    }
    p {
      flex: 1;
      font-weight: bold;
    }
    > button {
      width: 80px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      background: #0080d6;
      border-radius: 4px;
      border: 0;
      color: #fff;
      font-size: 14px;
      margin-right: 30px;
    }
    i {
      color: #666666;
    }
  }
  .lesson-detail {
    border-top: 1px solid #f0f0f0;
    .title2 {
      display: flex;
      align-items: center;
      height: 53px;
      padding-left: 31px;
      padding-right: 20px;
      font-size: 14px;
      color: #666666;
      img{
        margin-right: 5px;
      }
      p {
        flex: 1;
      }
      > button {
        width: 80px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background: #0080d6;
        border-radius: 4px;
        border: 0;
        color: #fff;
        font-size: 14px;
        margin-right: 30px;
      }
      > span {
        color: #999;
      }
    }
    .list3 {
      .title2 {
        border-top: 1px solid #f0f0f0;
        padding-left: 49px;
      }
    }
  }
  .free {
    width: 40px;
    height: 28px;
    background: #ff5d5e;
    border-radius: 5px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .play-btn {
    width: 27px;
    height: 27px;
    background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%BC%96%E7%BB%84%202%E5%A4%87%E4%BB%BD%202%402x.png")
      no-repeat center / contain;
  }

  .lesson-audio {
    flex: 1;
    margin: auto 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    height: 60px;
  }

  .lesson-audio .lesson-title {
    line-height: 22px;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    position: relative;
    font-size: 16px;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .lesson-msg {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 26px;
    color: #666;
    width: 100%;
  }

  .lesson-msg .lesson-time,
  .lesson-msg .lesson-count,
  .lesson-msg .lesson-status {
    display: flex;
    align-items: center;
    color: #666;
    margin-right: 20px;
    white-space: nowrap;
  }

  .lesson-msg img {
    height: 14px;
    margin-right: 5px;
  }

  .lesson-video {
    position: relative;
    float: left;
    margin-right: 10px;
  }

  .lesson-video img {
    display: block;
    width: 108px;
    height: 60px;
    border-radius: 5px;
  }

  .lesson-video .video-duration {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 10px;
    font-weight: 500;
    color: #fff;
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0 3px 3px 0;
  }

  .lesson-video::after {
    display: inline-block;
    position: absolute;
    top: 15px;
    left: 40px;
    content: "";
    width: 30px;
    height: 30px;
    background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/MTU4NDI2MDIzODA1.png")
      no-repeat center / contain;
  }
}
</style>