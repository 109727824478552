<template>
  <div>
    <el-dialog
      custom-class="infoSubmit"
      title=""
      :visible.sync="infoSubmit"
      width="563px"
      @close="orderShow = false"
    >
      <div class="formMain">
        <p>请您完善相关信息：</p>
        <div class="form">
          <div class="formRight">
            <img :src="form.cover || formImg" />
            <input type="file" accept=".png, .jpg, .jpeg" @change="uploadData" />
          </div>
          <div class="formLeft">
            <div class="line">
              <p>姓名</p>
              <span>:</span>
              <div><input v-model="form.name" placeholder="请输入您的姓名" /></div>
            </div>
            
            <div class="line">
              <p>手机号</p>
              <span>:</span>
              <div>{{ form.mobile }}</div>
            </div>
            <div class="line">
              <p>身份证号</p>
              <span>:</span>
              <div><input v-model="form.cid" placeholder="请输入您的身份证号" /></div>
            </div>
            <div class="line">
              <p>省市区</p>
              <span>:</span>
              <div>
                <myArea  @update="changeAdd"/>
              </div>
            </div>
            <div class="line">
              <p>单位名称</p>
              <span>:</span>
              <div><input v-model="form.duties" placeholder="请输入您的单位名称" /></div>
            </div>
          </div>
          
        </div>

        <div class="btn" @click="formSubmit">提交</div>
      </div>
    </el-dialog>
    <div class="examin-content" :class="{ bg2: !list }">
      <div class="examinLeft">
        <p class="examinTitle">{{ testInfo && testInfo.name }}</p>
        <!-- 模拟 -->
        <div class="examinDesc" v-if="type==0">
          <p><span>未答题</span>：{{testInfo.no_doing}}道</p>
          <p>已答题：{{testInfo.doing }}道</p><br/>
          <p><span>错题</span>：{{testInfo.error }}道</p>
          <p>正确率：{{testInfo.percentage }}%</p>
        </div>
        <!-- 正式 -->
        <div class="examinDesc" v-if="type==1">
          <p>答题时间：{{ testInfo.time_limit }}分钟</p>
          <p>满分：{{testInfo.score}}分</p><br/>
          <p>考试次数：{{testInfo.test_num}}/3次</p>
          <p>及格：{{ testInfo && testInfo.pass }}分</p>
        </div>
      </div>
      <div class="isPass" v-if="type==1 && testInfo.test_num">
          <img :src="testInfo.report_is_pass ? pass : noPass" />
      </div>
      <!-- 模拟 -->
      <div class="examinBtn" v-if="type==0 ">
        <div class="btn">
          <p v-if="!testInfo.doing" @click="toExamin()">开始答题</p>
          <p v-if="testInfo.doing" @click="toExamin()">继续答题</p>
          <!-- <p v-if="testInfo.doing" @click="toExamin('new')" class="blue">重新答题</p> -->
          <p v-if="testInfo.doing" @click="toResult()">查看试卷</p>
          
        </div>
      </div>
      <!-- 正式 -->
      <div class="examinBtn" v-if="type==1">
        <div class="btn">
          <p @click="toExamin()"  v-if="!testInfo.report_is_pass && testInfo.test_num < 3">开始答题</p>
          <p v-if="testInfo.test_num" @click="toResult(1)" class="blue">查看考试结果</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myArea from '@/components/area.vue'
import formSubmitImg from '@/assets/images/formSubmitImg.png'
import pass from '@/assets/images/pass.png'
import noPass from '@/assets/images/noPass.png'
export default {
  props: ["testInfo", 'type','list'],
  components:{myArea},
  data() {
    return {
      form: {
        gender:3,
        name: this.$store.state.user.nickname,
        mobile: this.$store.state.user.mobile,
        cid: "",
        cover: "",
        duties:'',
        province_id:''
      },
      formCheck:['name','mobile','cid','duties','province_id'],
      formImg: formSubmitImg,
      radio:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/formRadio.jpg",
      radio2:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/formRadio2.jpg",
      infoSubmit: false,
      // testInfo:{
      //   exam_pass: 0,
      //   name:'试卷题目',
      //   score: 100,
      //   question_number: 10,
      //   pass: 0,
      //   exam_time: '2022-10-10 12:12:12'
      // },
      pass,
      noPass,
    };
  },
  computed: {
    uid(){
      return this.$store.state.user.id;
    },
    vipInfo() {
      return this.$store.state.vipInfo;
    },
    platName() {
      return this.$store.state.plat.name;
    },
    plat() {
      return this.$store.state.plat_id;
    },
    VIPShow() {
      return this.$store.state.VIPShow;
    },
    logo() {
      return this.$store.state.plat.logo;
      // return "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyulogo.png";
    },
  },
  mounted() {},
  methods: {
    changeAdd(info){
      console.log(info)
      this.form.province_id = info[0].id
      this.form.province_name = info[0].name
      this.form.city_id = info[1].id
      this.form.city_name = info[1].name
      this.form.district_id = info[2].id
      this.form.district_name = info[2].name
    },
    uploadData(e) {
      console.log(e.target.files[0]);
      let file = e.target.files[0];
      // file.size < 1024 * 200 ||
      if (file.size > 1024 * 1024) {
        this.$toast("请上传符合要求的照片");
        return;
      }
      let param = new FormData();
      param.append("folder", file);
      this.$request.toUpload(param).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.form.cover = res.data.fullpath;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    formSubmit() {
      // if(this.$until.checkPhone(this.form.mobile)!='ok'){
      //   this.$toast(this.$until.checkPhone(this.form.mobile));
      //   return
      // }
      this.form.uid = this.uid
      if(this.$until.checkCard(this.form.cid)!='ok'){
        this.$toast(this.$until.checkCard(this.form.cid));
        return
      }
      if (this.formCheck.findIndex(item=>!this.form[item]) != -1) {
        this.$toast("请补全全部信息");
        console.log(this.form)
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$request.userInfoSubmit(this.form).then((res) => {
        loading.close();
        if (res.code == 0) {
          this.$router.push({
            path:'./examinList',
            query:{
              type:this.type,
              id: this.testInfo.id,
              time: this.testInfo.time_limit || 0
            }
          })
        } else {
          this.$toast(res.msg);
        }
      });
    },
    toResult(type){
      if(type){
        this.$router.push({
             path: "./examinResult",
             query: {
              examinResult:2,
              maxScore: this.testInfo.report_score,
              is_max_pass: this.testInfo.report_is_pass,
             }
           });
      }else{
        this.$router.push({
         path:'./examinResultImitate',
         query:{
           report_id:this.testInfo.report_id,
           uid: this.uid,
           test_id: this.testInfo.id,
         }
       })
      }
    },
    async toExamin(type) {
      let isStudy = this.testInfo.test_message == 1;
      console.log(this.testInfo)
      let baseInfo = this.$parent.baseInfo || this.$parent.$parent.baseInfo;
      if (!isStudy) {
        this.$toast("您的课程还没有全部学习完成，请学习完全部课程后在参加考试");
        return;
      }
      // if (!this.testInfo.is_message && this.type == 1) {
      //   this.infoSubmit = true;
      //   return;
      // }
      if(type=='new'){
         let res = await this.$api({
            url: "/api/test/wu/last",
            data: {
              uid: this.uid,
              test_id:this.testInfo.id
            },
          })
          if(res.code != 0){
            this.$toast(res.msg)(res.msg)
            return
          }
      }
      if(this.type==1 && this.testInfo.test_num == 3){
        this.$toast('作答次数已达到三次')
        return
      }
      this.$router.push({
          path:'./examinList',
          query:{
            type:this.type,
            id: this.testInfo.id,
            time: this.testInfo.time_limit || 0
          }
        })
      
      
    },
 
  },
};
</script>

<style scoped lang="scss">
.formMain {
  color: #333333;
  overflow: hidden;
  > p {
    font-size: 18px;
    margin-bottom: 36px;
  }
  .btn {
      width: 260px;
      height: 36px;
      cursor: pointer;
      margin: 20px auto 0;
      background: #0080d6;
      color: #fff;
      font-size: 16px;
      line-height: 36px;
      text-align: center;
      border-radius: 18px;
    }
  .form {
    display: flex;
    .formLeft {
      padding-left: 30px;
      margin-right: 50px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      .line {
        display: flex;
        align-items: center;
        width: 316px;
        margin-bottom: 15px;
        > p {
          font-size: 16px;
          width: 68px;
          text-align: justify;
          text-align-last: justify;
          margin-right: 3px;
        }
        > span {
          font-size: 16px;
        }
        > div {
          margin-left: 11px;
          padding-left: 10px;
          flex: 1;
          border-bottom: 1px solid #e4e8ed;
          line-height: 30px;
          height: 32px;
          display: flex;
          align-items: center;
          input {
            border: 0;
            height: 30px;
            background: transparent;
          }
          p {
            width: 79px;
            display: flex;
            align-items: center;
            color: #999999;
            cursor: pointer;
            img {
              margin-right: 8px;
            }
          }
          p.sexActive {
            color: #666;
          }
        }
      }
    }
    
    .formRight {
      width: 124px;
      height: 173px;
      position: relative;
      margin-left: 40px;
      border-radius: 8px;
      overflow: hidden;
      flex-shrink: 0;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  
}
.examin-content {
  display: flex;
  align-items: center;
  width: 780px;
  margin: 0 10px;
  background: #e6f3fc;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 20px 20px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666666;
}
.bg2 {
  background: #f7f7f7;
}
.isPass {
    margin-right: 85px;
  }
.examin-content .examinBtn {
  width: 122px;
  text-align: center;
  padding: 10px 0;
  color: #fff;
 
  .btn {
    color: #fff;
    font-size: 16px;
    p{
      width: 122px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      margin: 12px auto 0;
      background: #fff;
      color: #2e66d0;
    }
    p.blue{
      background: #2e66d0;
      color: #fff;
    }
  }
}
.opacity {
  opacity: 0.5;
}
.examin-content .examinLeft {
  flex: 1;
}
.examin-content .examinLeft .examinTitle {
  color: #000;
  width: 100%;
  font-size: 16px;
  margin-bottom: 16px;
}
.examin-content .examinLeft .examinDesc {
  overflow: hidden;
  p{
    width: 145px;
    display: inline-block;
    font-size: 14px;
    line-height: 30px;
    span{
        display: inline-block;
        width: 45px;
        text-align: justify;
        text-align-last: justify;
      }
  }
}
.examin-content .examinLeft .score span {
  margin-right: 50px;
}
</style>
